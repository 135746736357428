<template>
	<div class="doc-container">
		<div class="doc-header">
			<span class="title">帮助文档｜微信公众号授权设置</span>
		</div>
		<div class="doc-scroll">
			<div class="doc-main">
				<div class="doc-meau">
					<div class="doc-meau-contnet">
						<div class="doc-meau-directory">
							<div class="doc-directory-title">目录</div>
							<div class="doc-directory-container">
								<ul class="doc-directory-list">
									<li :class="['toc-entry', 'heading-1',levelOneIsCollapsed ? 'is-collapsed':'',focusIndex === 0 ? 'is-active': '']">
										<div class="dic-text" style="font-weight: 700;" @click="onScrollToRef('section1')">1.公众号授权</div>
									</li>
									<li :class="['toc-entry','heading-1',focusIndex === 1 ? 'is-active': '']">
										<span class="icon-collapsed" @click="onSwtichOneTable"><svg id="图层_1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 10 10" style="enable-background:new 0 0 10 10" xml:space="preserve"> <g id="Artboard"> <polygon id="Triangle-3-Copy-13" style="fill:#a5a5a5" points="8,5.5 3,9 3,2 " /> </g> </svg> </span>
										<div class="dic-text" style="font-weight: 700;" @click="onScrollToRef('section2')">2.配置域名</div>
									</li>
									<li v-show="!levelOneIsCollapsed" :class="['toc-entry','heading-2',focusIndex === 2 ? 'is-active': '']">
										<div class="dic-text" @click="onScrollToRef('section3')">2.1.配置IP白名单</div>
									</li>
									<li v-show="!levelOneIsCollapsed" :class="['toc-entry','heading-2',focusIndex === 3 ? 'is-active': '']">
										<div class="dic-text" @click="onScrollToRef('section4')">2.2.配置JS接口安全域名</div>
									</li>
									<li v-show="!levelOneIsCollapsed" :class="['toc-entry', 'heading-2',focusIndex === 4 ? 'is-active': '']">
										<div class="dic-text" @click="onScrollToRef('section5')">2.3.配置网页授权域名</div>
									</li>
									<li v-show="!levelOneIsCollapsed" :class="['toc-entry','heading-2',focusIndex === 5 ? 'is-active': '']">
										<div class="dic-text" @click="onScrollToRef('section6')">2.4.配置业务域名</div>
									</li>
									<li :class="['toc-entry','heading-1',focusIndex === 6 ? 'is-active': '']">
										<div class="dic-text" style="font-weight: 700;" @click="onScrollToRef('section7')">3.配置工作台入口</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<h3 class="doc-title">帮助文档｜微信公众号授权设置</h3>
				<div class="doc-document">
					<h6 ref="section1" class="sub-title">1.企业信息设置</h6>
					<h7 class="small-title">
						<b class="small-title-word" style="text-indent:22px;">1.1.</b>
						<p class="small-title-desc" style="padding-left: 50px;">登录<a href="https://mp.weixin.qq.com/" target="_blank" rel="noopener noreferrer nofollow">【微信公众后台】</a>，在左侧菜单中选择【设置与开发】模块，点开【公众号设置】栏目，找到「名称」项，复制粘贴到系统的「公众号名称」输入框内。</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px">
						<img class="help-imgs" alt="" src="https://xmf-bos.jwyun.net/hnjing-xmf/28d5caf28313f2bafbad5d43bf0dd152.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100" @click="onprevImg(0)">
					</p>
					<h7 class="small-title">
						<b class="small-title-word" style="text-indent:22px;">1.2.</b>
						<p class="small-title-desc" style="padding-left: 50px;">在【设置与开发】模块，找到【开发】-【基本配置】栏目，找到「开发者ID」和「开发者密钥」并复制，再回到 企业号 中粘贴到对应输入框中。</p>
					</h7>
					<p class="mod-img">
						<img class="help-imgs" alt="" src="https://xmf-bos.jwyun.net/hnjing-xmf/215552c395d554ae51718b033fa77887.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100" @click="onprevImg(1)">
					</p>
					<h5 ref="section2" class="main-title" style="padding-bottom:0;margin-top:20px;">2.配置域名</h5>
					<p style="text-indent:12px;line-height:18px;">了解说明：配置【IP白名单】【JS接口安全域名】【网页授权域名】是授权第三方最重要的一步；</p>
					<h6 ref="section3" class="sub-title" style="font-size:16px;">2.1.配置IP白名单</h6>
					<h7 class="small-title">
						<b class="small-title-word" style="width:70px;">2.1.1.</b>
						<p class="small-title-desc" style="padding-left: 70px;">在【基本配置】模块，找到【IP白名单】项并点开，将系统中的IP地址复制粘贴到IP白名单输入框中，再保存；</p>
					</h7>
					<p class="mod-img">
						<img class="help-imgs" alt="" src="https://xmf-bos.jwyun.net/hnjing-xmf/1addfe96ea7443715ba97fff0241d82d.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100" @click="onprevImg(2)">
					</p>
					<p class="mod-img" style="margin-bottom:20px;">
						<img class="help-imgs" alt="" src="https://xmf-bos.jwyun.net/hnjing-xmf/91bf97ffac217a61f4556d525382bc3a.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100" @click="onprevImg(3)">
					</p>
					<h6 ref="section4" class="sub-title" style="font-size:16px;">2.2.配置JS接口安全域名</h6>
					<h7 class="small-title">
						<b class="small-title-word" style="width:70px;">2.2.1.</b>
						<p class="small-title-desc" style="padding-left: 70px;">在微信公众平台中，点开【公众号设置】栏目，选择【功能设置】tab项，找到【JS接口安全域名】项，点击【设置】按钮</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px;">
						<img class="help-imgs" alt="" src="https://xmf-bos.jwyun.net/hnjing-xmf/0339b85c99dc249f7f737b9875d15c83.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100" @click="onprevImg(4)">
					</p>
					<h7 class="small-title">
						<b class="small-title-word" style="width:70px;">2.2.2.</b>
						<p class="small-title-desc" style="padding-left: 70px;">先下载txt文件，上传到系统中【txt授权文件】项；（必须先上传）</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px;">
						<img class="help-imgs" alt="" src="https://xmf-bos.jwyun.net/hnjing-xmf/2006e75f97119be87186e52756342261.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100" @click="onprevImg(5)">
					</p>
					<h7 class="small-title">
						<b class="small-title-word" style="width:70px;">2.2.3.</b>
						<p class="small-title-desc" style="padding-left: 70px;">再将系统中的【域名1】和【域名2】分别复制粘贴到JS接口安全郁闷的输入框中，最后保存；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px;">
						<img class="help-imgs" alt="" src="https://xmf-bos.jwyun.net/hnjing-xmf/8463dc0d903e22e8a992f59c536e9f50.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100" @click="onprevImg(6)">
					</p>
					<h6 ref="section5" class="sub-title" style="font-size:16px;">2.3.配置网页授权域名</h6>
					<h7 class="small-title">
						<b class="small-title-word" style="width:70px;">2.3.1.</b>
						<p class="small-title-desc" style="padding-left: 70px;">回到【功能设置】页，找到【网页授权域名】项，点击【设置】按钮；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px;">
						<img class="help-imgs" alt="" src="https://xmf-bos.jwyun.net/hnjing-xmf/21d8d127193fb367e1eaa5bc5791a269.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100" @click="onprevImg(7)">
					</p>
					<h7 class="small-title">
						<b class="small-title-word" style="width:70px;">2.3.2.</b>
						<p class="small-title-desc" style="padding-left: 70px;">再将系统中的【域名1】和【域名2】分别复制粘贴到网页授权域名的输入框中，最后保存；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px;">
						<img class="help-imgs" alt="" src="https://xmf-bos.jwyun.net/hnjing-xmf/5547e55b9598745eb370d8e40dea4078.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100" @click="onprevImg(8)">
					</p>
					<h6 ref="section6" class="sub-title" style="font-size:16px;">2.4.配置业务域名</h6>
					<h7 class="small-title">
						<b class="small-title-word" style="width:70px;">2.4.1.</b>
						<p class="small-title-desc" style="padding-left: 70px;">再次回到【功能设置】页，找到【业务域名】项，点击【设置】按钮；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px;">
						<img class="help-imgs" alt="" src="https://xmf-bos.jwyun.net/hnjing-xmf/0fead88ab4a5c69d102e3932fe2d1311.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100" @click="onprevImg(9)">
					</p>
					<h7 class="small-title">
						<b class="small-title-word" style="width:70px;">2.4.2.</b>
						<p class="small-title-desc" style="padding-left: 70px;">再将系统中的【域名1】和【域名2】分别复制粘贴到业务域名的输入框中，最后保存；</p>
					</h7>
					<p class="mod-img">
						<img class="help-imgs" alt="" src="https://xmf-bos.jwyun.net/hnjing-xmf/1458968c6018d54550ec03d1fcf55fdf.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100" @click="onprevImg(10)">
					</p>
					<h6 ref="section7" class="sub-title" style="margin-top:30px;">3.配置工作台入口</h6>
					<h7 class="small-title">
						<b class="small-title-word">3.1.</b>
						<p class="small-title-desc">在微信公众平台中，找到【内容与互动】模块，选择【自定义菜单】栏目，添加菜单后，填写「菜单名称」，粘贴工作台地址到「页面地址」输入框中，最后保存。</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px;">
						<img class="help-imgs" alt="" src="https://xmf-bos.jwyun.net/hnjing-xmf/f2d7ce97c094244458cdd5bf3569f738.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100" @click="onprevImg(11)">
					</p>
					<h7 class="small-title">
						<b class="small-title-word">3.2.</b>
						<p class="small-title-desc">完成上述步骤，回到系统中保存，即完成了配置操作。</p>
					</h7>
				</div>
			</div>
		</div>
		<jw-img-view :imgs="imgs" :visible.sync="visible" :select.sync="select" />
	</div>
</template>

<script>
var throttle = function (func, delay) {
  var timer = null
  return function () {
    var context = this
    var args = arguments
    if (!timer) {
      timer = setTimeout(function () {
        func.apply(context, args)
        timer = null
      }, delay)
    }
  }
}

export default {
  data () {
    return {
      levelOneIsCollapsed: false,
      visible: false,
      select: 0,
      focusIndex: null,
      imgs: [
        'https://xmf-bos.jwyun.net/hnjing-xmf/28d5caf28313f2bafbad5d43bf0dd152.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/215552c395d554ae51718b033fa77887.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/1addfe96ea7443715ba97fff0241d82d.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/91bf97ffac217a61f4556d525382bc3a.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/0339b85c99dc249f7f737b9875d15c83.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/2006e75f97119be87186e52756342261.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/8463dc0d903e22e8a992f59c536e9f50.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/21d8d127193fb367e1eaa5bc5791a269.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/5547e55b9598745eb370d8e40dea4078.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/0fead88ab4a5c69d102e3932fe2d1311.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/1458968c6018d54550ec03d1fcf55fdf.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/f2d7ce97c094244458cdd5bf3569f738.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100'
      ]
    }
  },
  mounted () {
    this.$el.querySelector('.doc-scroll').addEventListener('scroll', this.onscrollSection)
  },
  beforeDestroy () {
    this.$el.querySelector('.doc-scroll').removeEventListener('scroll', this.onscrollSection)
  },
  methods: {
    onscrollSection: throttle(function (e) {
      const indexs = [20, 796, 868, 1571, 2695, 3444, 4201]
      const height = e.target.scrollTop
      for (let i = 0; i < indexs.length; i++) {
        if ((i === (indexs.length - 1) && height > indexs[indexs.length - 1]) || (height > indexs[i] && height < indexs[i + 1])) {
          this.focusIndex = i
          break
        }
      }
    }, 200),
    onprevImg (index) {
      this.select = index
      this.visible = true
    },
    onSwtichOneTable () {
      this.levelOneIsCollapsed = !this.levelOneIsCollapsed
    },
    onScrollToRef (ref) {
      this.$el.querySelector('.doc-scroll').scrollTo(0, this.$refs[ref].offsetTop)
      setTimeout(() => {
        this.$refs[ref].className = [this.$refs[ref].className, 'is-focus'].join(' ')
        setTimeout(() => {
          this.$refs[ref].className = this.$refs[ref].className.split(' ').filter(item => item !== 'is-focus').join(' ')
        }, 600)
      }, 700)
    }
  }
}
</script>

<style lang="less">
.doc-container{
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  overflow: hidden;
  min-width: 1280px;
  .doc-header{
    white-space: nowrap;
    width: 100%;
    position: relative;
    background: linear-gradient(180deg,#fdfdfd,#fff);
    z-index: 110;
    height: 50px;
    box-shadow: 0 2px 3px 0 rgba(100,100,100,0.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    border-bottom: 1px solid #fff;
    .title{
      display: inline-block;
      vertical-align: middle;
      pointer-events: none;
      height: 50px;
      line-height: 24px;
      margin-left: 240px;
      box-shadow: none;
      background: inherit;
      max-width: 656px;
      padding-bottom: 4px;
      font-size: 14px;
      font-weight: 700;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 50px;
    }
  }
  .doc-scroll{
    height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 15px;
    scroll-behavior: smooth;
    .doc-main{
      margin: 0 auto;
      width: 816px;
      min-height: 800px;
      border: none;
      font-family: -apple-system,BlinkMacSystemFont,PingFang SC,Apple Color Emoji,Helvetica,Tahoma,Arial,Hiragino Sans GB,Microsoft YaHei,sans-serif;
      -webkit-font-smoothing: antialiased;
      height: auto!important;
      background: #fff;
      box-shadow: 0 1px 5px #ddd;
      .doc-title{
        box-sizing: content-box;
        margin: 0 100px;
        width: auto;
        font-size: 30px;
        height: 60px;
        line-height: 40px;
        padding-top: 90px;
        border-bottom: 1px solid #e8ecf1;
        font-family: -apple-system,BlinkMacSystemFont,PingFang SC,Apple Color Emoji,Helvetica,Tahoma,Arial,Hiragino Sans GB,Microsoft YaHei,sans-serif;
      }
      .doc-document{
        position: relative;
        padding: 20px 100px 100px;
        font-size: 11pt;
        line-height: 1.7;
        color: #494949;
        outline: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-height: 1172px;
        box-sizing: border-box;
        text-align: left;
        white-space: pre-wrap;
        word-wrap: break-word;
        counter-reset: chapter section subsec;
        .main-title{
          font-size: 16pt;
          line-height: 36px;
          color: #333;
          margin:0;
          padding-bottom: 8px;
          &.is-focus{
            background-color: rgba(245,212,122,.7);
          }
        }
        .sub-title {
          line-height: 28px;
          font-size: 18px;
          font-weight: bold;
          color: #333;
          text-indent: 10px;
          margin: 0;
          &.is-focus{
            background-color: rgba(245,212,122,.7);
          }
        }
        .small-title{
          position: relative;
          line-height: 26px;
          &.is-focus{
            background-color: rgba(245,212,122,.7);
          }
          &.blod{
            font-weight: bold;
            .small-title-word{
              font-weight: bold;
            }
          }
          .small-title-word{
            font-style: normal;
            font-weight: normal;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 60px;
            text-indent: 30px;
            z-index: 1;
            &.word-mark::after{
              // content: "\F104";
              content: '';
              background: #fff;
              display: inline-block;
              width: 4px;
              height: 4px;
              border: 1px solid #494949;;
            }
          }
          .small-title-desc{
            box-sizing: border-box;
            padding-left: 60px;
            &.is-focus{
              background-color: rgba(245,212,122,.7);
            }
            a{
              color: #70b1e7!important;
              cursor: pointer!important;
              text-decoration: none;
              word-break: break-all;
              a:hover{
                color: #1787cf!important;
              }
            }
          }
        }
        .mod-img {
          margin: 0;
          padding: 0;
          .help-imgs{
            max-width: 100%;
            display: block;
            opacity: 1;
            transition: opacity .5s ease-out;
            transform-origin: left top;
            max-width: 100%;
            outline: none;
            outline-offset: inherit;
            border: none;
            box-shadow: none;
            cursor: zoom-in;
          }
        }
        .mod-tips {
          margin:0;
          padding:0;
          a{
            color: #70b1e7!important;
            cursor: pointer!important;
            text-decoration: none;
            word-break: break-all;
            padding: 0 2px;
            a:hover{
              color: #1787cf!important;
            }
          }
          img{
            display: inline-block;
            vertical-align: text-bottom;
            margin-left: 14px;
            margin-top: 10px;
          }
        }
      }
      .doc-meau{
        position: fixed;
        box-sizing: border-box;
        top: 65px;
        height: 480px;
        width: 315px;
        margin-left: -315px;
        .doc-meau-contnet{
          position: absolute;
          width: 100%;
          min-width: 171px;
          font-size: 12px;
          box-sizing: border-box;
          padding: 0 20px 0 0;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          .doc-meau-directory{
            position: relative;
            visibility: visible;
            opacity: 1;
            width: 100%;
            height: 100%;
            text-align: left;
            color: #ccc;
            margin: 20px 0;
            padding: 0;
            overflow: hidden;
            transition: visibility .3s,opacity .3s ease-in-out;
            .doc-directory-title{
              position: relative;
              z-index: 2;
              background: #f7f7f7;
              color: #a5a5a5;
              border-bottom: 1px solid #ddd;
              padding-bottom: 10px;
              margin-left: 15px;
            }
            .doc-directory-container{
              max-height: 100%;
              box-sizing: border-box;
              overflow: hidden;
              padding-right: 7px;
              transition: transform .25s ease-in-out;
              transform: translateY(0);
              -ms-touch-action: auto;
              touch-action: auto;
              .doc-directory-list{
                height: auto;
                padding: 10px 0;
                list-style: none;
                margin: 0;
                li.toc-entry{
                  line-height: 18px;
                  border: none;
                  cursor: pointer;
                  padding: 6px 0 6px 15px;
                  color: #666;
                  &::before{
                    content: '';
                  }
                  &:hover{
                    color: #5e97d4;
                    .icon-collapsed{
                      display: block;
                    }
                  }
                  &.is-active{
                    color: #5e97d4;
                  }
                  &.heading-2{
                    margin-left: 15px;
                    padding-left: 10pt;
                  }
                  &.heading-3{
                    margin-left: 15px;
                    padding-left: 20pt;
                  }
                  &.is-collapsed .icon-collapsed {
                    display: block;
                    svg{
                      transform: rotate(0deg);
                    }
                  }
                  .icon-collapsed{
                    width: 15px;
                    height: 18px;
                    position: absolute;
                    padding-right: 2px;
                    padding-left: 3px;
                    transform: translateX(-100%);
                    display: none;
                    svg{
                      width: 10px;
                      height: 10px;
                      transform: rotate(90deg);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
